import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import { tnAuth, createPaymentProvider } from "../services/welcome.service";
import Wizard from "../components/Wizard";
import { useonboardingStore } from "../store";
import Loading from "../components/Loading";
import { motion } from "framer-motion";
import { pageTransition } from "../services/shared/framermotion.service";

export default function Welcome() {
  const onboardingData = useonboardingStore((state) => state.onboardingData);
  const addData = useonboardingStore((state) => state.addData);
  const [isFinished, setIsFinished] = useState(false);
  const removeOnboarding = useonboardingStore(
    (state) => state.removeOnboardingData,
  );
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const code = window.location.search;

  const goToNextStep = async () => {
    setIsFinished(true);
    setTimeout(() => {
      history.push("/conekta-integration");
    }, 500);
  };

  useEffect(() => {
    let isMounted = true;
    async function getUserData() {
      setIsLoading(true);
      const codeValue = code.split("=")[1];
      const result = await tnAuth(codeValue);
      if (isMounted) {
        setIsLoading(false);
        if (result && !result.error) {
          addData(result);
          setUserData(result);
        }
      }
    }
    if (Object.keys(userData).length === 0) {
      getUserData();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="flow-container onboarding-container">
      <div className="container-fluid">
        <Row className="row no-gutters">
          <Col xs={5} lg={4} className="left-col">
            <Wizard step="0"></Wizard>
          </Col>

          <Col xs={7} lg={8} className="overflow-hidden">
            <motion.div
              key="page-transition"
              variants={pageTransition}
              initial="initial"
              animate={isFinished === true ? "leave" : "join"}
              className="page-container d-flex align-items-center "
            >
              <div className="welcome">
                <p className="title-page color-primary">
                  <span className="color-text">Te estábamos esperando, </span>
                  {Object.keys(userData).length > 0
                    ? userData.storeName
                    : onboardingData.storeName}
                  !
                </p>
                <div className="description introText">
                  Ahora ya tienes integrado el procesador de pagos Conekta en tu
                  tienda Tiendanube. Para el siguiente paso crea una cuenta en
                  Conekta y comienza a recibir pagos con cualquier método:
                  tarjeta de débito, crédito, transferencia bancaria y efectivo.{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://identity.conekta.com/Account/Register?client_id=f508bb85-1188-4c08-beb3-a076e1d41f9e"
                  >
                    {" "}
                    Haz click aquí
                  </a>{" "}
                  para crear una cuenta en Conekta.
                  <p className="description">
                    ¿Necesitas ayuda?{" "}
                    <a
                      href="https://help.conekta.com/hc/es-419"
                      className="color-primary"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Contáctanos{" "}
                    </a>
                  </p>
                  <div className="contact">
                    <p className="description">
                      ¿Quiénes somos?{" "}
                      <a
                        href="https://conekta.com/"
                        className="color-primary"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Conekta.com{" "}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>

            {/* Footer */}
            <div className="footer">
              {/* Back button */}
              {/* <Button variant="link" className="float-left">
                  Cerrar
                </Button> */}

              {/* Next button */}
              {!isLoading ? (
                <Button
                  variant="primary"
                  className="float-right"
                  // disabled={!code}
                  onClick={goToNextStep}
                >
                  Siguiente
                  <i className="fas fa-chevron-right"></i>
                </Button>
              ) : (
                <Loading text="Cargando..." classParams="float-right" />
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
